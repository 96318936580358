<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        A hypothetical metal crystallizes in a face-centered cubic (FCC) unit cell structure. The
        edge length of its unit cell is
        <number-value :value="length" unit="\text{pm.}" />
      </p>

      <p class="mb-2">
        a) What is the atomic radius of this hypothetical metal atom in picometers (pm)?
      </p>

      <calculation-input
        v-model="inputs.radius"
        class="mb-5"
        prepend-text="$\text{Radius}:$"
        append-text="$\text{pm}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) Assuming the hypothetical metal has a molar mass of
        <number-value :value="molarMass" unit="\text{g/mol,}" />
        determine the density of the unit cell described above in g/cm<sup>3</sup>.
      </p>

      <calculation-input
        v-model="inputs.density"
        prepend-text="$\text{Density}:$"
        append-text="$\text{g/cm}^3$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';

export default {
  name: 'Question402',
  components: {
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        radius: null,
        density: null,
      },
    };
  },
  computed: {
    length() {
      return this.taskState.getValueBySymbol('length').content;
    },
    molarMass() {
      return this.taskState.getValueBySymbol('molarMass').content;
    },
  },
};
</script>
